import { useEffect, useState } from 'react'

function App() {
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch('/api/status')
    .then(res => res.text())
    .then(res => setStatus(res))
  });

  function determineRender(){
    let button;
    if (status === 'STOPPED') {
      button = <button disabled={isLoading} onClick={() => {
        setIsLoading(true);
        fetch('/api/start')
          .then(() => setStatus('STARTED'))
          .then(() => setIsLoading(false))
      }}>Start the Server</button>
    } 
    else {
      button = <button disabled={isLoading} onClick={() => {
        setIsLoading(true);
        fetch('/api/stop')
          .then(() => setStatus('STOPPED'))
          .then(() => setIsLoading(false))
      }}>Stop the Server</button>
    }
    return button;
  }

  return (
    <>
      <h1>The server is {status}</h1>
      {determineRender()}
    </>
  )
}

export default App
